import {
  OwnUpAccordionProps,
  OwnUpFillAccordion,
  OwnUpGridContainer,
  OwnUpGridItem,
  OwnUpGridOffset,
  OwnUpGridWrapper,
  ownUpLightTheme,
  OwnUpThemeProvider
} from '@rategravity/own-up-component-library';
import React, { PropsWithChildren, useState } from 'react';
import { getRequiredFields } from '../../modules/handle-sanity-data';
import { OverlineText, OverlineWrapper } from '../overline-text';
import { PortableTextComponent } from '../portable-text';
import {
  FaqItemWrapper,
  FaqListWrapper,
  FaqSectionListWrapper,
  MoreFAQsButton,
  StyledFaqSection
} from './elements';
import { FaqData, FaqDatum } from './types';

export const FaqItem = ({
  question,
  children,
  ...props
}: PropsWithChildren<Pick<FaqDatum, 'question'> & OwnUpAccordionProps>) => (
  <FaqItemWrapper>
    <OwnUpFillAccordion title={question} {...props}>
      {children}
    </OwnUpFillAccordion>
  </FaqItemWrapper>
);

export const FaqList = ({ faqData }: { faqData: FaqData }) => {
  const [activeFaq, setActiveFaq] = useState<string | false>(false);
  const handleChange = (faqId: string) => (_event: React.ChangeEvent<{}>, newExpanded: boolean) => {
    setActiveFaq(newExpanded ? faqId : false);
  };
  const faqs = faqData.map(({ question, answer }, idx) => {
    const faqId = String(idx);
    return (
      <FaqItem
        key={faqId}
        question={question}
        expanded={faqId === activeFaq}
        onChange={handleChange(faqId)}
      >
        {answer?.body && <PortableTextComponent blocks={answer?.body} />}
      </FaqItem>
    );
  });

  return <FaqListWrapper>{faqs}</FaqListWrapper>;
};

export const FaqSection = ({
  faqData,
  moreFaqsUrl
}: {
  faqData: Record<string, any>;
  moreFaqsUrl?: string;
}) => {
  const types: string[] = ['heading', 'faqs'];
  const content = faqData ? getRequiredFields(faqData, types) : {};

  return (
    <OwnUpThemeProvider theme={ownUpLightTheme}>
      <StyledFaqSection aria-labelledby="faqSectionHeading">
        <OverlineWrapper>
          <OverlineText id="faqSectionHeading">
            {content?.headingObj?.heading || 'FAQS'}
          </OverlineText>
        </OverlineWrapper>
        <OwnUpGridWrapper>
          <OwnUpGridContainer variant="legacy">
            <OwnUpGridOffset xs={0} lg />
            <OwnUpGridItem xs={12} lg={10} xl={8}>
              <FaqSectionListWrapper>
                <FaqList faqData={content?.faqsObj?.pageBuilder || []} />
              </FaqSectionListWrapper>
              {moreFaqsUrl ? <MoreFAQsButton href={moreFaqsUrl} /> : null}
            </OwnUpGridItem>
            <OwnUpGridOffset xs={0} lg />
          </OwnUpGridContainer>
        </OwnUpGridWrapper>
      </StyledFaqSection>
    </OwnUpThemeProvider>
  );
};
